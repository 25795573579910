.tabs_container .am-tabs-default-bar-content {
  position: fixed;
  background: #fff;
  /* width: calc(100% - 30px); */
  width: 100%;
}
.my-drawer {
  position: absolute;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.my-drawer .am-drawer-sidebar {
  background-color: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.my-drawer .am-drawer-sidebar .am-list {
  width: 300px;
  padding: 0;
}
.my-drawer  .am-drawer-draghandle{
  margin-left: -10px;
}
.tabs_container .am-tabs-default-bar-tab{
  position: relative;
  font-weight: 400;
  color: rgb(196,204,220);
  font-size: 16px;
}
.tabs_container .am-tabs-default-bar-tab-active{
  position: relative;
  color: #7C90B6!important;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 2px;
  box-sizing: border-box;
}
.tabs_container .am-tabs-default-bar-underline{
  height:3px;
  position: absolute;
  bottom: 3px;
  background:rgba(124,144,182,1);
}
.tabs_container .am-list-body{
  background: transparent;
}
.hiedcheck .am-checkbox-inner{
  display: none;
}