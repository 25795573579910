.am-modal-content{
    width: 344px;
    margin-left: -37px;
}
.am-modal-transparent .am-modal-content {
    padding-top: 0;
}
table{
    border: 1px solid #000000;
}
table td{
    width: 33.3%;
    padding:0 8px;
    border-bottom: 1px solid #000000;
    border-left: 1px solid #000000;
    height: 55px;
    color: #000000;
    font-size: 14px;
    text-align: left;
}
.am-modal-alert-content{
    padding: 20px;
    min-height: 50px;
}
.ant-radio-inner::after{
    top:4px;
    left: 4px;
}
.ant-radio-group{
    display: inline;
}

.am-segment-item{
    padding: 8px;
    margin: 0 3px;
    border:1px solid;
}
.am-segment-item:first-child{
    border-radius: unset;
}
.am-segment-item:last-child{
    border-radius: unset;
}
.circle .am-checkbox-inner{
    background-color: white;
    width: 16px;
    height: 16px;
    border-radius: 2px;
}
.circle .am-checkbox.am-checkbox-checked .am-checkbox-inner{
    background-color: white;
    border-color: white;
}
.circle .am-checkbox.am-checkbox-checked .am-checkbox-inner:after{
    border-color: #0e80d2;
    top: -1px;
    right: 4px;
    width: 4px;
    border-width: 0 2px 2px 0;
}
.circle .am-checkbox-agree .am-checkbox-agree-label{
    margin-left: 18px;
}
.nullCar .am-list-item .am-list-line .am-list-extra{
    color: #888;
}
.carFooter .am-modal-button-group-v .am-modal-button{
    color: #2580F3;
}

.ant-input-group-addon{
    background-color: #2283F3;
    color: white;
}

/**{*/
/*    touch-action: none;*/
/*}*/
